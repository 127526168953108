import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './EmailVerification.css';

function EmailVerification() {
    const { displayName } = useParams(); // Get the dynamic segment from the URL

    return (
        <div className="email-verification-container">
        <div className="card">
            <h1>Email Verified 🎉</h1>
            <p>Your email has been successfully verified. Welcome aboard!</p>
        </div>
      </div>
    );
}

export default EmailVerification;