import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './ApproveRequest.css';

function ApproveRequest() {

    const { name, expenseName } = useParams();

    return (
        <div className="approved-container">
            <div className="approved-card">
                <h1>Request Approved</h1>
                <p>You have successfully placed {name} into pending tags.</p>
                <strong>Receipt: {expenseName} </strong>
            </div>
        </div>
    );
}

export default ApproveRequest;