import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import EmailVerification from './Email Verification/EmailVerification';
import ApproveRequest from './Approve Pending Tags/ApproveRequest'
import FailedApprove from './Approve Pending Tags/FailedApproval';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/email_verified" element={<EmailVerification />} />
      <Route path="/approved_request/:name/:expenseName" element={<ApproveRequest />} />
      <Route path="/failed_approve_request/:name/:expenseName" element={<FailedApprove />} />
    </Routes>
    {/* <App /> */}
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
