import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './FailedApprove.css';

function FailedApprove() {

    const { name, expenseName } = useParams();

    return (
        <div className="failed-container">
            <div className="failed-card">
                <h1>Request Failed</h1>
                <p>We are unable to placed {name} into pending tags at this time.</p>
                <strong>Receipt: {expenseName} </strong>
            </div>
        </div>
    );
}

export default FailedApprove;